import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Masterclass Àmbit C per Mossos d'Esquadra 2023/24</Title>
                <Text>
                    <div className="space-y-5">
                        <p>
                            <strong>La MASTERCLASS D'ÀMBIT C en directe.</strong>
                        </p>
                        <p>Una formació totalment renovada i actualitzada en cada convocatòria.</p>
                        <p>La clase s'imparteix en directe mitjançant Zoom.</p>
                        <p>
                            La sessió queda grabada al campus per poder veure-ho en cas de no poder
                            asistir a la sessió o simlement per veure-ho de nou.
                        </p>
                        <p>
                            La masterclass consta d'un repàs intens dels conceptes més importants i
                            habituals de l'examen d'aquest àmbit.
                        </p>
                        <p>
                            A mesura que s'apropi la nova convocatòria de Mossos d'Esquadra
                            2024/2025 tornarem a portar aquesta formació.
                        </p>
                    </div>
                    <br />
                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/lzdNHaNuXY0"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
